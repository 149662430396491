import './app.css';
import ScrollIndicator from './scroll-indicator';
import Content from './content';

function App() {

    const copyToClipboard = () => {
        // Get the text field
        const textField = document.getElementById('textToCopy');

        //console.log('Texto copiado: '+ textField.innerText);
    
        // Use the Clipboard API
        navigator.clipboard.writeText(textField.innerText);
    }

    const BackToTop = () => {
        window.scrollTo({
        top: 0,
        behavior: "smooth",
        });
    };


    const scrollEnd = () => {
        window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
        });
    };


  return (
    <>
        <div className='sticky'>
            
            <div className="flex-col">

                <a className="repo_link banner" href="https://github.com/sarahcssiqueira/scroll-bar-indicator/fork" 
                    aria-label="Fork sarahcssiqueira/base-wp-theme on GitHub">
                        <i className="fa-solid fa-code-fork"></i> Fork
                </a>

                <h1>Scroll Indicator Component - Demo</h1>

                    <p className='code' id="textToCopy">npm i scroll-bar-indicator 
                   
                    <button className="copy-button" onClick={copyToClipboard}>
                        <i className="fa-regular fa-copy"></i>
                    </button> </p>
                    
                <ScrollIndicator/>

                <div className='repo_info'>

                    <a className="repo_link" href="https://github.com/sarahcssiqueira/scroll-bar-indicator#readme" 
                                        aria-label="Readme sarahcssiqueira/base-wp-theme on GitHub">
                        <i className="fa-solid fa-book-open"></i> Readme
                    </a>

                    <a className="repo_link" href="https://github.com/sarahcssiqueira/scroll-bar-indicator" 
                                    aria-label="Star sarahcssiqueira/base-wp-theme on GitHub">
                        <i className="fa-regular fa-star"></i> Star
                    </a>

                </div>

            </div>
            
        </div>

        <div className='flex-col'>
                <i class="fa-solid fa-angles-down content_scroll_icon" onClick={scrollEnd}></i>
         </div>

        <Content/>

        <div className='flex-col'>

            <i className="fa-solid fa-angles-up content_scroll_icon" onClick={BackToTop}></i>
            <p>Happy coding!</p>
        </div>
        
    </>
  )
}

export default App;