import { useEffect, useState } from 'react';

function Content() {
    const contentSource = 'https://baconipsum.com/api/?type=meat-and-filler&&start-with-lorem=1&paras=25';
    const [text, setText] = useState ([]);
    
    useEffect( () => {
        fetch(contentSource)
        .then( (res) => res.json())
        .then((res) =>  {
        setText(res);
        });
    }, []);

    return (
        <>
            <div className='content_text'>
                <p>{text}</p>
                <p>{text}</p>
                <p>{text}</p>
                <p>{text}</p>
                <p>{text}</p>
            </div>
        </>
    )
}

export default Content;