import { useState, useEffect, useCallback } from 'react';

function ScrollIndicator() {
    const [scroll, setScroll] = useState(0);

    const onScroll = useCallback(() => {
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
        const maxHeight = scrollHeight - clientHeight;
        const scrolledPercent = ( scrollTop / maxHeight) * 100;
        setScroll(scrolledPercent);
    }, []);

    useEffect (() => {
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, [onScroll]);
        
    const scrollString = Math.trunc(scroll);

    return (
        <div className='scroll_box'>
            <div className="scroll_bar">
                <div style={{ width: `${scroll}%` }} className="scroll_indicator"></div>
                <p className='scroll_info'>{'Text '}{scrollString}{'% scrolled'}</p>
            </div>
        </div>
    )
}

export default ScrollIndicator;